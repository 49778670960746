/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import useLocalStorageFetch from '../../../../hooks/localStorageFetch'
import {useSelector} from 'react-redux'

export function AsideMenuMain() {
  const intl = useIntl()
  const dataUser: any = useSelector((state) => state)
  const {role} = useLocalStorageFetch()
  return (
    <>
      <AsideMenuItem
        to={'/dashboard'}
        title='Dashboard'
        icon='/media/ePulse/icons/aside/dashboard.svg'
        heading='Home'
        className='py-3'
      ></AsideMenuItem>
      {dataUser?.auth?.role === 'Doctor' && (
        <AsideMenuItemWithSubMain
          to='/rxmodule'
          title='Create Rx'
          icon='/media/ePulse/icons/aside/prescription.svg'
          heading='RX'
        >
          <AsideMenuItem to={`/${role}/rx_template`} title='Template' />
          <AsideMenuItem to={`/${role}/rx_prescription`} title='Prescription' />
        </AsideMenuItemWithSubMain>
      )}

      {dataUser?.auth?.role === 'Doctor' && (
        <AsideMenuItemWithSubMain
          to='/settingssection'
          title='Setting'
          icon='/media/ePulse/icons/aside/setting.svg'
          heading='Setting'
        >
          <AsideMenuItem
            to={`/${role}/application_setting/prescription_designer`}
            title='Settings'
          />
          <AsideMenuItem to={`/${role}/prescription-codes`} title='Prescription Codes' />
        </AsideMenuItemWithSubMain>
      )}
      {dataUser?.auth?.role === 'Doctor' && (
        <>
          <AsideMenuItem
            to={`/${role}/patients`}
            title='Patients'
            icon='/media/ePulse/icons/aside/vendor.png'
            heading='Patients'
            className='py-3'
          ></AsideMenuItem>
          <AsideMenuItemWithSubMain
            to={`/${role}/refer`}
            title='Doctor'
            icon='/media/ePulse/icons/aside/refer.png'
            heading='Refer'
          >
            <AsideMenuItem to={`/${role}/refer`} title='Refer'></AsideMenuItem>
            <AsideMenuItem to={`/${role}/clinic-doctors`} title='Doctors'></AsideMenuItem>
          </AsideMenuItemWithSubMain>
        </>
      )}
      {dataUser?.auth?.role === 'ScanCenter' && (
        <>
          <AsideMenuItemWithSubMain
            to='/settingssection'
            title='Settings'
            icon='/media/ePulse/icons/aside/setting.svg'
            heading='Settings'
          >
            <AsideMenuItem to={`/${role}/booking_slot_scan`} title='Booking Slot' />
          </AsideMenuItemWithSubMain>
        </>
      )}
      {dataUser?.auth?.role === 'Pharmacy' && (
        <>
          <AsideMenuItem
            to={`/${role}/inventory`}
            title='inventory'
            icon='/media/ePulse/icons/aside/inventory.png'
            heading='Inventory'
            className='py-3'
          ></AsideMenuItem>
          <AsideMenuItem
            to={`/${role}/vendor`}
            title='vendor'
            icon='/media/ePulse/icons/aside/vendor.png'
            heading='Vendor'
            className='py-3'
          ></AsideMenuItem>
          <AsideMenuItem
            to={`/${role}/patient`}
            title='patients'
            icon='/media/ePulse/icons/aside/patient.png'
            heading='Patients'
            className='py-3'
          ></AsideMenuItem>
        </>
      )}
      {dataUser?.auth?.role === 'ScanCenter' && (
        <>
          <AsideMenuItem
            to={`/${role}/patientupload`}
            title='inventory'
            icon='/media/ePulse/icons/aside/patient.png'
            heading='Patients'
            className='py-3'
          ></AsideMenuItem>
        </>
      )}

      {/* <AsideMenuItem
        to='/reports'
        title='Reports'
        icon='/media/ePulse/icons/aside/reports.svg'
        heading='Reports'
        className='py-3'
      ></AsideMenuItem> */}
      {dataUser?.auth?.role === 'Doctor' && (
        <AsideMenuItemWithSubMain
          to='/summary'
          title='Summary'
          fontIcon='bi-sticky'
          icon='/media/ePulse/icons/aside/inventory.png'
          heading='Summary'
        >
          {/* <AsideMenuItem to={`/${role}/summary`} title='Summary' /> */}
          <AsideMenuItem to={`/${role}/payments`} title='Payments' />

          <AsideMenuItem to={`/${role}/settlements`} title='Settlements' />
        </AsideMenuItemWithSubMain>
      )}
    </>
  )
}
